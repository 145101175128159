import * as React from "react";
import Layout from "../components/layout";

import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import star1 from "../images/star1.svg";
import star2 from "../images/star2.svg";
import star3 from "../images/star3.svg";

import sticker from "../images/vector_sticker.svg";

import Helmet from "react-helmet";

export default function Index() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>McPhee's Alberta</title>
        <meta name="description" content="Award-winning wall map shows a unique level of historical and geographic detail."/>
        <meta name="image" content="https://awmcphee.ca/gp.jpg" />
        <meta property="og:image" content="https://awmcphee.ca/gp.jpg"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@ksituan"/>
        <meta name="twitter:title" content="McPhee's Alberta"/>
        <meta name="twitter:description" content="Award-winning wall map shows a unique level of historical and geographic detail."/>
        <meta name="twitter:image" content="https://awmcphee.ca/gp.jpg"/>
      </Helmet>
      <h1>McPhee's Alberta</h1>
      <h2>Award-winning wall map shows the province in unique detail</h2>
      <div className="awards">
        <Link className="mapLink" to="/alberta.jpg" target="_blank" rel="noopener noreferrer"><StaticImage
          className="bigmap linkedMap"
          src="../images/50dpi.jpg"
          alt="50dpi preview of map"
        /></Link>
        <a href="https://cartogis.org/awards/map-competition" target="_blank" rel="noopener noreferrer"><img
          src={star1}
          className="star1"
          alt="The CaGIS Best Reference Map award, 2020"
        /></a>
        <a href="https://www.cartography.org.uk/2020-21-awards-entries" target="_blank" rel="noopener noreferrer"><img
          src={star2}
          className="star2"
          alt="Highly Commended in the BSC Stanfords Award, 2020-21"
        /></a>
        <a href="https://nacis.org/awards/2019-winner-mcphee" target="_blank" rel="noopener noreferrer"><img
          src={star3}
          className="star3"
          alt="The NACIS Best in Design award, 2019"
        /></a>
      </div>
      <p><em>Click map to open preview in new tab. (7.84MB)</em></p>
      <h3>As covered by...</h3>
      <div className="media"><div className="logo"><a href="https://www.canadiangeographic.ca/article/canadian-mapmakers-celebrate-wins-international-map-design-competition" target="_blank" rel="noopener noreferrer"><StaticImage
            src="../images/cangeo.png"
            alt="The logo of Canadian Geographic."
          /></a></div>
      <div className="logo"><a href="https://www.cartography.org.uk/2020-21-awards-entries" target="_blank" rel="noopener noreferrer"><StaticImage
            src="../images/british_society.png"
            alt="The logo of the British Cartographic Society."
          /></a></div>
      <div className="logo"><a href="https://cartogis.org/awards/map-competition" target="_blank" rel="noopener noreferrer"><StaticImage
          src="../images/cagis.png"
          alt="The logo of the Cartography and Geographic Information Society."
        /></a></div>
      <div className="logo"><a href="/library.png" target="_blank" rel="noopener noreferrer"><StaticImage
            src="../images/ualberta.png"
            alt="The logo of the University of Alberta."
          /></a></div>
        </div>
      <p>I'm a self-taught, independent cartographer.</p>
      <p>
        This map was created over the last three years, using open-source
        software and public-domain resources. It has won multiple international
        awards.
      </p>
      <p>
        I have ensured its accuracy by personally visiting every county in the
        province.
      </p>
      <p>I invite you to experience Alberta as I see it.</p>
      <h2>Features</h2>
      <div className="skew">
        <div class="barrier"/>
        <Link to="/store" className="buyfloat" aria-hidden="true">
          <img src={sticker} alt="A link to the store." />
        </Link>
        <StaticImage
          className="skewWrapper"
          imgClassName="skewImage"
          src="../images/peace_skew.jpg"
          alt="50dpi preview of map"
        />
        <div className="mill">
          <div className="mark"></div>
          <div className="mark-label">Lumber mill</div>
        </div>
        <div className="lookout">
          <div className="mark"></div>
          <div className="mark-label">Lookout tower</div>
        </div>
        <div className="beaver">
          <div className="mark"></div>
          <div className="mark-label">Giant beaver</div>
        </div>
      </div>
      <p>
        The “reference map” is a traditional style of wall map.
        The idea is simple: provide a clear and balanced overview of everything that's interesting.
      </p>
      <p>
        I started personally travelling to collect data in 2018.
        But Alberta was even more interesting than I thought, so packing it all into a single canvas ended up taking about 1,000 hours.
      </p>
      <p>Here are some things you won't see on any other map:</p>
      <p>How big was the 2016 Fort McMurray wildfire?</p>
      <StaticImage
        className="map"
        src="../images/mcmurray.jpg"
        alt="A red boundary is drawn in the hills around Fort McMurray."
      />
      <p>How much of Edmonton is built on the Papaschase reserve?</p>
      <StaticImage
        className="map"
        src="../images/edmonton.jpg"
        alt="A dotted red line, labeled Papaschase First Nation, surrounds most of Mill Woods."
      />
      <p>How many Hutterite colonies are there?</p>
      <StaticImage
        className="map"
        src="../images/hutterite.jpg"
        alt="A large number of gray dots can be seen south of Lethbridge."
      />
      <p>
        Or maybe you're just interested in a complete inventory of abandoned
        railway stations, parks and public lands, First Nations and traplines,
        campgrounds provincial and public.
      </p>
      <StaticImage
        className="map"
        src="../images/legend.jpg"
        alt="The map's legend shows a long list of different kinds of features."
      />
      <h2>Buy</h2>
      <p>McPhee's Alberta finally came into the world during April 2021, when I ordered 2,000 copies from a Canadian offset printing press.
      </p>
      <p>
        At 42" x 68", the map brings immaculate geographic detail to any indoor space.
        Plus, it's extremely affordable for a wall-size print from an independent artist.
      </p>
      <p>Maps are $70 laminated, $60 unlaminated.</p>
      <p class="arrow">Get one here.</p>
      <Link to="/store" className="buy">
        <img src={sticker} alt="A link to the store." />
      </Link>
    </Layout>
  );
}
